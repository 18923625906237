/*Below code is not part of actual template*/
.ColorSwitcher, .ColorSwitcher * {
  box-sizing: border-box;
}

.ColorSwitcher {
  position: fixed;
  top: 40%;
  right: -220px;
  width: 220px;
  padding: 20px;
  background: #fff;
  border-radius: 0 3px 3px 0;
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
  -webkit-transition: right .2s;
  transition: right .2s;
  z-index: 1081;
}

.ColorSwitcher--open {
  right: 0;
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.15);
}

.ColorSwitcher__control, .ColorSwitcher__switch {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.ColorSwitcher__control {
  position: absolute;
  right: 0;
  right: 100%;
  color: #fff;
  background: #23272b;
}

.ColorSwitcher__control:before {
  content: "\f5aa";
  font-family: 'Font Awesome 5 Free';
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
}

.ColorSwitcher--open .ColorSwitcher__control {
  background: #fff;
  box-shadow: 10px 0px 30px rgba(83, 88, 93, 0.10);
}

.ColorSwitcher--open .ColorSwitcher__control:before {
  color: #000;
}

.ColorSwitcher__switchs {
  margin: -5px;
}

.ColorSwitcher__switch {
  border-radius: 3px;
  margin: 5px;
  width: 180px;
}

.ColorSwitcher h6 {
  font-size: 16px;
  margin-bottom: 10px;
}

.ColorSwitcher p {
  font-size: 13px;
  line-height: 18px;
}

.rtlswitcher {
  position: fixed;
  right: 0;
  top: 41%;
  color: #fff;
  background: #23272b;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  cursor: pointer;
  transition: all .3s;
  text-align: center;
  line-height: 40px;
  z-index: 1080;
  transform: translateY(-40%);
  right: -110px;
  width: 150px;
}
.rtlswitcher i{
  font-size: 16px;
  color: #fff;
  margin-right: 15px;
}
.rtlswitcher:hover{
  right: 0px;
  color: #fff;
  background: #23272b;
}